module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-1422116-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"M. Seyda Tartar's Personal Web Site","short_name":"mek.app","start_url":"/","background_color":"#37474f","theme_color":"#37474f","display":"minimal-ui","icon":"src/images/profile.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"109624357b5c8b39085805bbeb24e9bc"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
